import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

import Navbar from '../../components/Navbar/Navbar';

import { useAppSelector } from '../../redux/hooks';

import { Section1 } from '../../components/SellOnDrifflePage/Section1';
import { Section2 } from '../../components/SellOnDrifflePage/Section2';
import { Section3 } from '../../components/SellOnDrifflePage/Section3';
import { BodyWrapper } from '../../styles/pageStyles/sellOnDriffleStyle';
import { getUserInfoSSR } from '../../utils/cf';

// interface Props {
//   meta: string;
// }

const SellOnDriffle = () => {
  const router = useRouter();
  const { isLoggedIn, user } = useAppSelector((state) => state.user);
  const [redirectIntent, setRedirectIntent] = useState<boolean>(false);

  useEffect(() => {
    if (isLoggedIn && redirectIntent) {
      router.push('/sell-on-driffle/verification');
    }
  }, [redirectIntent, isLoggedIn]);

  return (
    <>
      <Head>
        <title>Sell on Driffle - Driffle</title>
      </Head>
      <Header />
      <Navbar />
      <BodyWrapper>
        <Section1 />
        <Section2 />
        <Section3 />
      </BodyWrapper>
      <Footer />
    </>
  );
};

export default SellOnDriffle;

export async function getServerSideProps(context: any) {
  try {
    const userInfo = await getUserInfoSSR(context.req, context.res);
    return {
      props: {
        reduxInitialState: userInfo,
      },
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}
