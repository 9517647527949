import { useLocale } from '../../contexts/LocalizationContext';
import { BackgroundIcon } from '../Icons/Icons';
import {
  Section2CardBottom,
  Section2CardContainer,
  Section2CardContent,
  Section2CardDescription,
  Section2CardTitle,
  Section2CardTop,
  Section2Container,
  Section2ContentTitle,
  Section2IconBox,
  Section2Wrapper,
} from './SellOnDriffleStyles';

export const Section2 = () => {
  let { messages } = useLocale();
  const {
    why_partner_with_driffle_msg,
    lowest_commission_in_the_world_msg,
    enjoy_up_to_percent_lower_msg,
    fastest_payouts_including_crypto_msg,
    get_your_payouts_within_msg,
    seller_support_msg,
    were_here_round_the_clock_msg,
    comprehensive_analytics_msg,
    understand_your_sales_performance_better_msg,
    easy_mobile_access_msg,
    seamlessly_manage_your_store_with_msg,
    premium_features_for_free_msg,
    features_like_declared_stock_and_msg
  } = messages || {};

  const WhyPartnerList = [
    {
      icon: '/icons/sell-on-driffle/lowest-commission.svg',
      title: lowest_commission_in_the_world_msg,
      description: enjoy_up_to_percent_lower_msg?.replace('[_PERCENT_]', '70%'),
    },
    {
      icon: '/icons/sell-on-driffle/fastest-payouts.svg',
      title: fastest_payouts_including_crypto_msg,
      description: get_your_payouts_within_msg,
    },
    {
      icon: '/icons/sell-on-driffle/seller-support.svg',
      title: seller_support_msg,
      description: were_here_round_the_clock_msg,
    },
    {
      icon: '/icons/sell-on-driffle/comprehensive-analytics.svg',
      title: comprehensive_analytics_msg,
      description: understand_your_sales_performance_better_msg,
    },
    {
      icon: '/icons/sell-on-driffle/easy-mobile.svg',
      title: easy_mobile_access_msg,
      description: seamlessly_manage_your_store_with_msg,
    },
    {
      icon: '/icons/sell-on-driffle/premium-feature.svg',
      title: premium_features_for_free_msg,
      description: features_like_declared_stock_and_msg,
    },
  ];

  return (
    <Section2Wrapper>
      <Section2Container>
        <Section2ContentTitle style={{ marginBottom: '24px' }}>
          {why_partner_with_driffle_msg}
        </Section2ContentTitle>

        <Section2CardContainer>
          {WhyPartnerList.map((data: any, index: number) => {
            return (
              //   <Section2Card>
              //     <Section2IconBox>
              //       {/* <BackgroundIcon
              //         url={data.icon}
              //         // margin="0 6px 0 0"
              //         height="24px"
              //         width="24px"
              //         selected
              //       /> */}
              //     </Section2IconBox>
              //     <Section2CardContent>
              //       <Section2CardTitle>{data.title}</Section2CardTitle>
              //       <Section2CardDescription>
              //         {data.description}
              //       </Section2CardDescription>
              //     </Section2CardContent>
              //   </Section2Card>
              <Section2CardContent key={index}>
                <Section2CardTop>
                  <Section2IconBox>
                    <BackgroundIcon
                      url={data.icon}
                      // margin="0 6px 0 0"
                      height="24px"
                      width="24px"
                      selected
                    />
                  </Section2IconBox>
                  <Section2CardTitle>{data.title}</Section2CardTitle>
                </Section2CardTop>
                <Section2CardBottom>
                  <Section2CardDescription>
                    {data.description}
                  </Section2CardDescription>
                </Section2CardBottom>
              </Section2CardContent>
            );
          })}
        </Section2CardContainer>
      </Section2Container>
    </Section2Wrapper>
  );
};
