import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { WIDTH } from '../../constants/screenResolution';
import { useLocale } from '../../contexts/LocalizationContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { openLoginModalRedux } from '../../redux/modalSlice';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import { BackgroundIcon } from '../Icons/Icons';
import Button from '../NewButton/Button';
import {
  Section3Card,
  Section3CardContainer,
  Section3CardDescription,
  Section3CardIconMobile,
  Section3CardMobile,
  Section3CardMobileContent,
  Section3CardTitle,
  Section3Container,
  Section3ContentTitle,
  Section3IconBox,
  Section3Wrapper,
} from './SellOnDriffleStyles';

export const Section3 = () => {
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();
  const [redirectIntent, setRedirectIntent] = useState<boolean>(false);
  const router = useRouter();
  const { isLoggedIn, user } = useAppSelector((state) => state.user);
  useEffect(() => {
    if (isLoggedIn && redirectIntent) {
      router.push('/sell-on-driffle/verification');
    }
  }, [redirectIntent, isLoggedIn]);

  let { messages } = useLocale();
  const {
    upload_your_documents_msg,
    upload_your_valid_documents_to_msg,
    get_verified_in_hrs_msg,
    after_you_submit_your_valid_msg,
    start_selling_msg,
    list_your_products_on_driffle_sell_msg,
    heres_how_it_works_msg,
  } = messages || {};

  const howItWorksList = [
    {
      icon: '/icons/sell-on-driffle/upload-your-docs.svg',
      title: upload_your_documents_msg,
      description: upload_your_valid_documents_to_msg,
    },
    {
      icon: '/icons/sell-on-driffle/get-verified.svg',
      title: get_verified_in_hrs_msg,
      description: after_you_submit_your_valid_msg,
    },
    {
      icon: '/icons/sell-on-driffle/start-selling.svg',
      title: start_selling_msg,
      description:
        'List your products on Driffle and start selling your products to 1M+ customers from across 160 countries.',
    },
  ];
  return (
    <Section3Wrapper>
      <Section3Container>
        <Section3ContentTitle style={{ marginBottom: '24px' }}>
          {heres_how_it_works_msg}
        </Section3ContentTitle>
        <Section3CardContainer>
          {howItWorksList.map((data) => {
            return (
              <>
                {!!width &&
                width < getNumFromResolutionConstant(WIDTH.tabletMax) ? (
                  <Section3CardMobile>
                    <Section3CardIconMobile>
                      <BackgroundIcon
                        url={data.icon}
                        // margin="0 6px 0 0"
                        height="54px"
                        width="54px"
                      />
                    </Section3CardIconMobile>
                    <Section3CardMobileContent>
                      <Section3CardTitle>{data.title}</Section3CardTitle>
                      <Section3CardDescription>
                        {data.description}
                      </Section3CardDescription>
                    </Section3CardMobileContent>
                  </Section3CardMobile>
                ) : (
                  <Section3Card>
                    <Section3IconBox>
                      <BackgroundIcon
                        url={data.icon}
                        // margin="0 6px 0 0"
                        height="64px"
                        width="64px"
                        selected
                      />
                    </Section3IconBox>

                    <Section3CardTitle>{data.title}</Section3CardTitle>
                    <Section3CardDescription>
                      {data.description}
                    </Section3CardDescription>
                  </Section3Card>
                )}
              </>
            );
          })}
        </Section3CardContainer>
        <Button
          onClick={
            isLoggedIn
              ? user?.store_id && user?.storeDetails?.store_status
                ? () => router.push('/user/selling/dashboard')
                : () => router.push('/sell-on-driffle/verification')
              : () => {
                  dispatch(openLoginModalRedux());
                }
          }
          buttonTheme="primary"
          height="48px"
          width="fit-content"
        >
          {start_selling_msg}
        </Button>
      </Section3Container>
    </Section3Wrapper>
  );
};
