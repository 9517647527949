import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useLocale } from '../../contexts/LocalizationContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { openLoginModalRedux } from '../../redux/modalSlice';
import Button from '../NewButton/Button';
import {
  ContactUsBtn,
  Section1Container,
  Section1Content,
  Section1Wrapper,
} from './SellOnDriffleStyles';

export const Section1 = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { isLoggedIn, user } = useAppSelector((state) => state.user);
  const [redirectIntent, setRedirectIntent] = useState<boolean>(false);
  let { messages } = useLocale();
  const { become_a_seller_on_the_msg, start_selling_msg, contact_us_msg } =
    messages || {};

  useEffect(() => {
    if (isLoggedIn && redirectIntent) {
      router.push('/sell-on-driffle/verification');
    }
  }, [redirectIntent, isLoggedIn]);

  return (
    <>
      <Section1Wrapper>
        <Section1Container>
          <Section1Content>
            <h6>{become_a_seller_on_the_msg}</h6>
            <div style={{ display: 'flex', gap: '16px' }}>
              <Button
                onClick={
                  isLoggedIn
                    ? user?.store_id && user?.storeDetails?.store_status
                      ? () => router.push('/user/selling/dashboard')
                      : () => router.push('/sell-on-driffle/verification')
                    : () => {
                        dispatch(openLoginModalRedux());
                      }
                }
                buttonTheme="primary"
                height="48px"
                width="fit-content"
              >
                {start_selling_msg}
              </Button>
              <Link
                href={'mailto:business@driffle.com'}
                passHref
                prefetch={false}
              >
                <a>
                  <ContactUsBtn width="fit-content" height="48px">
                    {contact_us_msg}
                  </ContactUsBtn>
                </a>
              </Link>
            </div>
          </Section1Content>
          <Image
            src={'/icons/sell-on-driffle/Sell-on-driffle.webp'}
            height="337px"
            width="678px"
            objectFit="contain"
            alt="Sell on Driffle Image"
          />
          {/* <SellerSideImage
            src={'/icons/sell-on-driffle/Sell-on-driffle.webp'}
            height="337px"
            width="678px"
          /> */}
        </Section1Container>
      </Section1Wrapper>
    </>
  );
};
